<template>
    <div class="article-form-box">

        <div class="form-content-box">

            <div class="form-container">
                <div class="form-body">

                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>所属分类</span>
                                <em>*</em>
                            </div>
                        </div>
                        <div class="item">
                            <span class="category-title">{{ category.title }}</span>
                        </div>
                    </div>

                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>文章标题</span>
                                <em>*</em>
                            </div>
                        </div>
                        <div class="item">
                            <n-input placeholder="请输入文章标题" v-model:value="formData.title" />
                        </div>
                    </div>

                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>关键字</span>
                            </div>
                        </div>
                        <div class="item keywords">
                            <n-tag 
                                class="tag"
                                theme="fill" type="primary"
                                v-for="(item, index) in formData.keywords" :key="index"
                                @close="removeKeyword(index)"
                            >
                                {{ item }}
                            </n-tag>
                            <n-input
                                class="keyword-input"
                                v-model:value="keyword"
                                @enter="addKeyword"
                                placeholder="输入关键字"
                            />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>描述</span>
                            </div>
                        </div>
                        <div class="item">
                            <n-textarea 
                                v-model:value="formData.description"
                                :maxlength="240"
                            ></n-textarea>
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-container form-editor">
                <n-editor 
                    class="form-content" 
                    v-model:data="formData.content"
                />
            </div>

        </div>

        <div class="form-slider">
            <div class="item">
                <h5>作者</h5>
                <div class="info author">
                    <n-avatar :size="40" />
                    <div class="name-box">
                        <span class="name">{{ $store.state.userInfo.name }}</span>
                        <span class="phone">{{ $store.state.userInfo.phone }}</span>
                    </div>
                </div>
            </div>
            <div class="item">
                <h5>发表状态</h5>
                <div class="info">
                    <n-select 
                        :zIndex="9999"
                        v-model:value="formData.status.label" 
                        :options="statuses" 
                        placeholder="请选择状态"
                        @choose="checkStatus"
                    />
                </div>
            </div>
            <div class="item">
                <h5>访问权限</h5>
                <div class="info">
                    <n-select 
                        :zIndex="9999"
                        v-model:value="formData.permissions.label" 
                        placeholder="请选择文章访问权限"
                    >
                        <template v-for="(item, index) in permissionses" :key="index">
                            <n-select-option 
                                :label="item.label" :index="index"
                                :value="formData.permissions"
                                :checked="formData.permissions.label === item.label"
                                @choose="checkPermission"
                            />
                        </template>
                    </n-select>
                </div>
            </div>
            <div class="item">
                <h5>发表时间</h5>
                <div class="info published">
                    <n-date-picker class="one" :date="formData.published_at.date"  @change="dateChange" />
                    <n-time-picker class="one" :time="formData.published_at.time" position="bottom right" @change="timeChange" />
                </div>
            </div>
            <div class="item">
                <h5>缩略图</h5>
                <div class="info thumb">
                    <n-upload v-model:src="formData.thumb" width="auto" :height="150" />
                </div>
            </div>
        </div>

    </div>

    <div class="bottom-tools-box">
        <n-button class="tool-btn" size="large" @click="reset">重置</n-button>
        <n-button 
            class="tool-btn" 
            size="large" 
            type="primary" 
            :loading="btnLoading"
            :disabled="formData.title === '' || formData.content === ''"
            @click="submit"
        >确定</n-button>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import NEditor from '@/components/layout/Editor.vue';

@Options({
    components: {
        NEditor
    },
    props: {
        category: {
            type: Object,
            default: {
                id: '',
                title: '',
                type: ''
            }
        },
        data: {
            type: Object,
            default: {}
        },
        btnLoading: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            formData: {
                author: '',
                cat_id: '',
                title: '',
                keywords: [],
                description: '',
                content: '',
                status: {
                    label: '发布',
                    value: 'published'
                },
                permissions: {
                    label: '公开',
                    value: 'public'
                },
                published_at: {
                    date: {},
                    time: {}
                }
            },
            keyword: '',
            statuses: [
                {
                    label: '发布',
                    value: 'published'
                },{
                    label: '草稿',
                    value: 'draft'
                },{
                    label: '回收站',
                    value: 'deleted'
                }
            ],
            permissionses: [
                {
                    label: '私有',
                    value: 'private'
                },{
                    label: '公开',
                    value: 'public'
                }
            ]
        }
    },
    watch: {
        'data':{
            handler(){
                this.formData = this.data;
            },
            deep: true
        },
        'category': {
            handler(){
                this.formData.cat_id = this.category.id;
            },
            deep: true
        }
    },
    emits: ['submit'],
    mounted(){
        this.$api.getUserInfo().then((res: any) => {
            this.$store.commit('changeUserInfo', res.data);
            this.formData.author = res.data.name;
        });
    },
    methods: {
        addKeyword(){
            this.formData.keywords.push(this.keyword);
            this.keyword = ''
        },
        removeKeyword(index: number){
            this.formData.keywords.splice(index, 1);
        },
        checkStatus(e: any){
            this.formData.status = e;
        },
        checkPermission(index: number){
            this.formData.permissions = this.permissionses[index]
        },
        dateChange(e: any){
            this.formData.published_at.date = e;
            console.log(e)
        },
        timeChange(e: any){
            this.formData.published_at.time = e;
        },
        reset(){
            location.reload();
        },
        submit(){
            this.$emit('submit', this.formData);
        }
    }
})

export default class ArticleForm extends Vue {}
</script>

<style lang="scss" scoped>
@import 'form-style';
.article-form-box{
    @extend .flex-row-between;
    width: 100%;
    .form-content-box{
        width: 74%;
        .form-container{
            margin: 0;
            .form-item .keywords{
                @extend .flex-row;
                flex-wrap: wrap;
                .tag{
                    margin-right: 10px;
                }
                .keyword-input{
                    width: 90px;
                }
            }
            &.form-editor{
                width: 100%;
                margin-top: 10px;
                padding: 0;
            }
        }
    }
}
</style>