
import { Options, Vue } from 'vue-class-component';
import NEditor from '@/components/layout/Editor.vue';

@Options({
    components: {
        NEditor
    },
    props: {
        category: {
            type: Object,
            default: {
                id: '',
                title: '',
                type: ''
            }
        },
        data: {
            type: Object,
            default: {}
        },
        btnLoading: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            formData: {
                author: '',
                cat_id: '',
                title: '',
                keywords: [],
                description: '',
                content: '',
                status: {
                    label: '发布',
                    value: 'published'
                },
                permissions: {
                    label: '公开',
                    value: 'public'
                },
                published_at: {
                    date: {},
                    time: {}
                }
            },
            keyword: '',
            statuses: [
                {
                    label: '发布',
                    value: 'published'
                },{
                    label: '草稿',
                    value: 'draft'
                },{
                    label: '回收站',
                    value: 'deleted'
                }
            ],
            permissionses: [
                {
                    label: '私有',
                    value: 'private'
                },{
                    label: '公开',
                    value: 'public'
                }
            ]
        }
    },
    watch: {
        'data':{
            handler(){
                this.formData = this.data;
            },
            deep: true
        },
        'category': {
            handler(){
                this.formData.cat_id = this.category.id;
            },
            deep: true
        }
    },
    emits: ['submit'],
    mounted(){
        this.$api.getUserInfo().then((res: any) => {
            this.$store.commit('changeUserInfo', res.data);
            this.formData.author = res.data.name;
        });
    },
    methods: {
        addKeyword(){
            this.formData.keywords.push(this.keyword);
            this.keyword = ''
        },
        removeKeyword(index: number){
            this.formData.keywords.splice(index, 1);
        },
        checkStatus(e: any){
            this.formData.status = e;
        },
        checkPermission(index: number){
            this.formData.permissions = this.permissionses[index]
        },
        dateChange(e: any){
            this.formData.published_at.date = e;
            console.log(e)
        },
        timeChange(e: any){
            this.formData.published_at.time = e;
        },
        reset(){
            location.reload();
        },
        submit(){
            this.$emit('submit', this.formData);
        }
    }
})

export default class ArticleForm extends Vue {}
